@tailwind base;
@tailwind components;

.hero {
  background-image: url(/images/hero.jpg);
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    @apply inset-0 absolute bg-brand-900 opacity-90;
  }
}

// Doesn't seem to be a more Tailwind-y way of doing this
// https://github.com/tailwindlabs/tailwindcss/issues/1102
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

h1, .h1 {
  @apply text-4xl;
}

h2, .h2 {
  @apply text-3xl;
}

h3, .h3 {
  @apply text-2xl;
}

h4, .h4 {
  @apply text-xl;
}

h5, .h5 {
  @apply text-lg;
}

.link, .question__body a, .prose a {
  @apply text-brand no-underline hover:underline font-semibold;
}

.toc {
  ul ul {
    @apply pl-4;
  }
}

.question, .solution {
  p, pre {
    @apply mb-4;
  }

  code, pre {
    @apply rounded-lg bg-gray-200;
  }

  code {
    padding: .1em .2em;
  }

  pre code {
    padding: 0;
  }

  pre {
    @apply p-4;
  }

  &__body {
    h1, h2, h3 {
      @apply mb-2;
    }

    li {
      @apply pl-1;
    }

    li::marker {
      @apply text-brand;
    }

    ul, ol {
      @apply pl-6 mb-4;
    }

    ul ul, ol ol {
      @apply mt-2;
    }

    ol {
      list-style-type: decimal;
    }

    ul {
      list-style-type: disc;
    }

    pre {
      white-space: pre-wrap;
    }
  }

  li + li {
    @apply mt-2;
  }
}

.badge {
  @apply bg-accent text-white rounded-sm py-1 px-1.5 text-xs font-normal;
}

.content {
  @apply mx-auto pt-10 pb-16 max-w-3xl;
}

.difficulty-indicator--Easy {
  line:nth-child(1), line:nth-child(2) {
    stroke: #C2CAD6;
  }
}

.difficulty-indicator--Medium {
  line:nth-child(2) {
    stroke: #C2CAD6;
  }
}

@tailwind utilities;
